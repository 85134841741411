<template>
    <v-container>
        <center><span> Totais: </span></center>
        <v-row v-if="this.estatisticasGerais" class="justify-center" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b>Alunos c/dificuldades</b> </span> </p>
                        <span> <b>{{this.estatisticasGerais.alunosComDificuldades}}</b> </span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b>Média diagnóstico Hypatimat</b> </span> </p>
                        <span><b>{{this.estatisticasGerais.mediaDiagnosticoHypatiamat}}</b></span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b>Média final Hypatiamat</b> </span> </p>
                        <span> <b> {{this.estatisticasGerais.mediaFinalHypatiamat}} </b> </span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b> Incremento média </b> </span> </p>
                        <span> <b>{{this.estatisticasGerais.incrementoMedia}}</b> </span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b> Apps NTRC </b> </span> </p>
                        <span> <b>{{this.estatisticasGerais.appsNTRC}}</b> </span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b> Apps acerto(%) </b> </span> </p>
                        <span> <b>{{this.estatisticasGerais.appsAcerto}}</b> </span>
                    </center>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                <v-card class="white--text" color="#009263" style="border: 2px solid black !important;">
                    <center>
                        <p> <span> <b> Jogos concluídos </b> </span> </p>
                        <span> <b>{{this.estatisticasGerais.jogosConcluidos}}</b> </span>
                    </center>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </template>
    
    <script>
    const h = require("@/config/hosts").hostAPI
    
      export default {
        data(){
          return {
            token: "",
          }
        },
        props:["estatisticasGerais"],
        created: async function(){
            this.token = localStorage.getItem("token")
        },
        methods: {
         
        }
      }
    </script>